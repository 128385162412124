import { FunctionComponent } from 'react';
import SearchPageComponent from 'components/search/SearchPage';

type SearchPageProps = {
  release?: string;
};

const SearchPage: FunctionComponent<SearchPageProps> = ({ release }) => (
  <SearchPageComponent release={release} />
);

export default SearchPage;
