import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import {
  InstantSearch,
  SearchBox,
  useHits,
  UseHitsProps,
  Pagination,
} from 'react-instantsearch';
import { getSearchResults } from 'services/search';
import { SearchRequest } from 'interfaces/search';
import { LangContext } from 'contexts/LanguageContext';
import { SmartFilter } from 'components/search/Filter';
import { HitUi } from 'components/search/HitUi';
import NoResults, { NoResultsBoundary } from 'components/search/NoResults';

import './search.scss';

type SearchPageProps = {
  release?: string;
};

function ShowHits(props: UseHitsProps) {
  const { items } = useHits({ ...props, escapeHTML: false });

  return (
    <div className="search-hits">
      {items.map((hit) => (
        <div key={hit.objectID}>
          <HitUi hit={hit as any} />
        </div>
      ))}
    </div>
  );
}

const SearchPageComponent: FunctionComponent<SearchPageProps> = ({
  release,
}: SearchPageProps) => {
  const { lang } = useContext(LangContext);
  const searchClient = {
    search: (requests: Array<SearchRequest>) => {
      const filter =
        requests[0].params.facetFilters?.length > 0
          ? requests[0].params.facetFilters[0][0]
          : null;
      return getSearchResults(
        requests[0].params.query,
        filter,
        lang,
        release,
        requests[0].params.page
      ) as any;
    },
  };
  let timerId = undefined as any;

  const queryHook = (query: string, search: Function) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      const queryTrimmed = query.trim();
      if (queryTrimmed === '') {
        return;
      }
      search(queryTrimmed);
    }, 200);
  };

  const algoliaSearchClass = release
    ? 'algolia-search search-page release'
    : 'algolia-search search-page';
  return (
    <div className="page-content">
      {release && (
        <h2 className="heading-02 title">DataRobot Release {release}</h2>
      )}
      <div className={algoliaSearchClass} test-id="algolia-search">
        <InstantSearch indexName="q" searchClient={searchClient} routing={true}>
          <SearchBox
            placeholder={t`Search documentation`}
            test-id="algolia-search-input"
            queryHook={queryHook}
          />
          <SmartFilter />
          <NoResultsBoundary fallback={<NoResults />}>
            <ShowHits />
          </NoResultsBoundary>
          <Pagination />
        </InstantSearch>
      </div>
    </div>
  );
};

export default SearchPageComponent;
