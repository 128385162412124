import { Fragment } from 'react';
import { t } from 'ttag';
import { Badge } from '@datarobot/design-system/badge';
import Hit from 'interfaces/hit';
import ItemIcon from 'components/search/ItemIcon';
import { getCategoryName } from 'components/search/Filter';

export function HitUi({ hit }: { hit: Hit }) {
  return (
    <div className="hit">
      <div className="hit-title">
        <div className="hit-icon">
          <ItemIcon category={hit.url && hit.url.split('/')[3]} />
        </div>
        <div className="hit-path">
          {hit.breadcrumbs && (
            <div className="breadcrumbs">
              {hit.breadcrumbs.slice(0, -1).map((title, i) => (
                <Fragment key={`${title}-${i}`}>
                  <span>{title}</span>
                  {i !== hit.breadcrumbs.length - 2 && (
                    <span className="delim">/</span>
                  )}
                </Fragment>
              ))}
            </div>
          )}
          {!hit.breadcrumbs && (
            <div className="breadcrumbs">
              <span>{t`DataRobot Documentation`}</span>
            </div>
          )}
        </div>
      </div>
      <div>
        {/* 
          For some reason, I had to do dangerouslySetInnerHTML on all of these to get the
          highlighting to work. If I did not do that, then I ended up with HTML in the page.
          That makes sense, except the Highlihght and Snippet componenents from InstantSearch
          are supposed to take care of that and did not.
         */}
        <div className="hit-details">
          <div className="hit-item">
            <a href={`${hit.url}#${hit.anchor}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: hit._highlightResult.title?.value,
                }}
              />
            </a>
          </div>
          <div className="hit-badge">
            <Badge>{getCategoryName(hit.itemType)}</Badge>
          </div>
          <div className="hit-section">
            {hit._highlightResult.section?.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: hit._highlightResult.section?.value || t`No section`,
                }}
              />
            )}
          </div>
        </div>
        <div
          className="hit-content"
          dangerouslySetInnerHTML={{
            __html: hit._snippetResult.content?.value,
          }}
        ></div>
      </div>
    </div>
  );
}
