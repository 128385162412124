import { FunctionComponent } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import DocsAssistProvider from 'contexts/DocsAssistContext';
import { DocsAssistDataProvider } from 'contexts/DocsAssistDataContext';

import { DocsAssistPage, HomePage, SearchPage } from 'pages';

export const DocsAssistRouter: FunctionComponent = () => (
  <DocsAssistProvider>
    <DocsAssistDataProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:lang" element={<HomePage />} />
        {/*This MUST be kept in sync with the values in `react_pages` in app.py*/}
        <Route path="/:lang/docs-assist" element={<DocsAssistPage />} />
        <Route path="/:lang/search" element={<SearchPage />} />
        <Route
          path="/11.0/:lang/search"
          element={<SearchPage release="11.0" />}
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </DocsAssistDataProvider>
  </DocsAssistProvider>
);

const Router: FunctionComponent = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/:lang" element={<HomePage />} />
    {/*This MUST be kept in sync with the values in `react_pages` in app.py*/}
    <Route path="/:lang/search" element={<SearchPage />} />
    <Route path="/11.0/:lang/search" element={<SearchPage release="11.0" />} />
    <Route path="/*" element={<Navigate to="/" />} />
  </Routes>
);

export default Router;
