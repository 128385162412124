import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import { LangContext } from 'contexts/LanguageContext';

import './search.scss';

const SearchForm: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <div className="algolia-search">
      <div className="ais-SearchBox">
        <form
          className="ais-SearchBox-form"
          method="GET"
          action={`/${lang}/search`}
          role="search"
          onSubmit={(e) => {
            const data = new FormData(e.target as HTMLFormElement);
            const query = data.get('q[query]')?.toString() || '';
            const queryTrimmed = query.trim();
            if (queryTrimmed === '') {
              e.preventDefault();
            }
          }}
        >
          <input
            type="search"
            placeholder={t`Search documentation`}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            maxLength={512}
            className="ais-SearchBox-input"
            name="q[query]"
          />
          <button
            type="submit"
            title="Submit your search query."
            className="ais-SearchBox-submit main-page-search"
          >
            <svg
              className="ais-SearchBox-submitIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 40 40"
            >
              <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
            </svg>
          </button>
          <button
            type="reset"
            title="Clear the search query."
            className="ais-SearchBox-reset main-page-cancel"
          >
            <svg
              className="ais-SearchBox-resetIcon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              width="10"
              height="10"
            >
              <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchForm;
