import { FunctionComponent, useContext } from 'react';
import { c, t } from 'ttag';
import { PageLayout } from 'components/page-layout';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as GetStartedIcon } from 'assets/images/platform/release.svg';
import { ReactComponent as UiIcon } from 'assets/images/platform.svg';
import { ReactComponent as ApiIcon } from 'assets/images/api.svg';
import { ReactComponent as ReleaseIcon } from 'assets/images/tutorials.svg';
import { ReactComponent as AdministrationIcon } from 'assets/images/platform/administration.svg';
import { ReactComponent as WorkbenchIcon } from 'assets/images/workbench-folder-icon.svg';
import { ReactComponent as GenaiIcon } from 'assets/images/genai.svg';

const cardsContent = (lang: string) => {
  const content = [
    {
      key: 'get-started',
      icon: <GetStartedIcon />,
      title: c('get started').t`Get Started`,
      description: t`Analyze data, create models, and write code`,
      link: t`/${lang}/docs/get-started/index.html`,
    },
    {
      key: 'gen-ai',
      icon: <GenaiIcon />,
      title: t`GenAI`,
      description: t`Create vector databases and LLM blueprints and prepare for deployment`,
      link: t`/${lang}/docs/gen-ai/index.html`,
    },
    {
      key: 'nextgen',
      icon: <WorkbenchIcon />,
      title: t`NextGen UI`,
      description: t`Docs for NextGen UI-based DataRobot use`,
      link: t`/${lang}/docs/workbench/index.html`,
    },
    {
      key: 'ui',
      icon: <UiIcon />,
      title: t`Classic UI`,
      description: t`Docs for classic UI-based DataRobot use`,
      link: t`/${lang}/docs/index.html`,
    },
    {
      key: 'api',
      icon: <ApiIcon />,
      title: t`API docs`,
      description: t`Docs for code-based DataRobot use`,
      link: t`/${lang}/docs/api/index.html`,
    },
    {
      key: 'administrators-guide',
      icon: <AdministrationIcon />,
      title: t`Administrator's guide`,
      description: t`Get help managing the DataRobot application`,
      link: t`/${lang}/docs/platform/index.html`,
    },
    {
      key: 'release',
      icon: <ReleaseIcon />,
      title: t`Releases`,
      description: t`Managed AI Cloud and on-prem release information`,
      link: t`/${lang}/docs/release/index.html`,
    },
  ];

  return content;
};

const Home: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <PageLayout
      title={t`Welcome to DataRobot documentation`}
      subtitle={t`Find all the information you need to succeed with DataRobot, in a style that suits you best.`}
      cardsContent={cardsContent(lang)}
      cardsWidth="large"
    />
  );
};

export default Home;
