const stringToBoolean = (str: string) => str.toLowerCase() === 'true';

export const apiDocsEnabled = () => {
  const envVar = process.env.REACT_APP_API_CLIENT_DOCS_ENABLED;
  console.log('apiDocsEnabled', String(envVar));
  return envVar && stringToBoolean(envVar);
};

export const experimentalDocsEnabled = () => {
  const envVar = process.env.REACT_APP_EXPERIMENTAL_DOCS_ENABLED;
  return envVar && stringToBoolean(envVar);
};
