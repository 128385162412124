import { FunctionComponent, useContext } from 'react';
import { t, c } from 'ttag';
import { Link, NavLink } from 'react-router-dom';
import { Sidebar } from 'components/sidebar';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as Logo } from 'assets/images/DR_Logo_White.svg';

import './header.scss';

type NavProps = {
  key: string;
  title: string;
  route: string;
  class?: string;
  isStaticPage?: boolean;
};

const externalLinks = () => [
  {
    key: 'trial',
    title: t`Start a free trial`,
    route: t`https://www.datarobot.com/trial/?utm_source=productbanner&utm_medium=web&utm_campaign=FreeTrial23productcta`,
    class: 'external',
  },
  {
    key: 'feedback',
    title: t`Feedback`,
    route: t`mailto:doc-feedback@datarobot.com?subject=DataRobot%20feedback`,
    class: 'external',
  },
  {
    key: 'university',
    title: t`DataRobot University`,
    route: t`https://university.datarobot.com`,
    class: 'external',
  },
  {
    key: 'dataRobot',
    title: t`DataRobot.com`,
    route: t`https://www.datarobot.com`,
    class: 'external',
  },
];

const internalLinks = (lang: string) => [
  {
    key: 'get-started',
    title: t`Get started`,
    route: t`/${lang}/docs/get-started/index.html`,
    isStaticPage: true,
  },
  {
    key: 'gen-ai',
    title: t`GenAI`,
    route: t`/${lang}/docs/gen-ai/index.html`,
    isStaticPage: true,
  },
  {
    key: 'nextgen',
    title: t`NextGen UI`,
    route: t`/${lang}/docs/workbench/index.html`,
    isStaticPage: true,
  },
  {
    key: 'ui',
    title: t`Classic UI`,
    route: t`/${lang}/docs/index.html`,
    isStaticPage: true,
  },
  {
    key: 'api',
    title: t`API docs`,
    route: t`/${lang}/docs/api/index.html`,
    isStaticPage: true,
  },
  {
    key: 'admin',
    title: c('docs-portal-header').t`Admin`,
    route: t`/${lang}/docs/platform/index.html`,
    isStaticPage: true,
  },
  {
    key: 'release',
    title: t`Releases`,
    route: t`/${lang}/docs/release/index.html`,
    isStaticPage: true,
  },
];

const Header: FunctionComponent = () => {
  const { lang, switchLang } = useContext(LangContext);
  return (
    <header className="header">
      <Sidebar
        internalLinks={internalLinks(lang)}
        lang={lang}
        switchLang={switchLang}
      />
      <div className="header-container">
        <Link to={`/${lang}`} title={t`logo`} className="logo">
          <Logo />
        </Link>
        <div className="navigations">
          <nav className="first-level">
            <ul className="first-level-navigation">
              {externalLinks().map((item: NavProps) => (
                <li key={item.key}>
                  <a
                    href={item.route}
                    test-id={item.key}
                    className={`${item.class} body`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="second-level">
            <nav>
              <ul className="second-level-navigation">
                {internalLinks(lang).map((item: NavProps) => (
                  <li key={item.key}>
                    {item.isStaticPage ? (
                      <a
                        href={item.route}
                        test-id={item.key}
                        className="white-button action-item"
                      >
                        {item.title}
                      </a>
                    ) : (
                      <NavLink
                        to={item.route}
                        test-id={item.key}
                        className="white-button action-item"
                      >
                        {item.title}
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
