import { FunctionComponent, useState } from 'react';
import {
  Stats,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch';
import { Button, ACCENT_TYPES } from '@datarobot/design-system/button';
import { t, ngettext, msgid } from 'ttag';

import './filter.scss';
import { apiDocsEnabled } from 'services/flags';

export function getCategoryName(itemType?: string): string | {} {
  const categories: { [category: string]: string } = {
    all: t`All`,
    api: t`API`,
    sdk: t`API Clients`,
    nextGen: t`NextGen`,
    genAi: t`GenAI`,
    release: t`Releases`,
    accelerators: t`AI accelerators`,
    classic: t`Classic`,
  };
  if (!apiDocsEnabled()) {
    delete categories.sdk;
  }
  return itemType ? categories[itemType] : categories;
}

interface FiltersProps {
  itemType: string | null;
  onSelect: Function;
  totalHits?: number;
}

const Filters: FunctionComponent<FiltersProps> = ({
  itemType,
  onSelect,
  totalHits,
}) => {
  const { items } = useRefinementList({ attribute: 'itemType' });
  return (
    <div className="filter">
      <div className="button-group">
        {Object.entries(getCategoryName()).map(([key, facetName]) => {
          const filterType = key === 'all' ? null : key;
          const facetCount =
            key === 'all'
              ? totalHits
              : items.find(({ value }) => value === key)?.count;
          return (
            <Button
              key={key}
              accentType={
                itemType === filterType
                  ? ACCENT_TYPES.PRIMARY
                  : ACCENT_TYPES.COMMAND
              }
              className="action-item"
              onClick={() => {
                onSelect(filterType);
              }}
            >
              {facetName}
              {facetCount ? <> ({facetCount})</> : null}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export function SmartFilter() {
  const { indexUiState, setIndexUiState, refresh, results } =
    useInstantSearch();
  const totalHits = results?._rawResults?.[1]?.nbHits || results.nbHits;

  const [itemType, setItemType] = useState<string | null>(
    indexUiState.refinementList?.itemType?.[0] || null
  );
  const updateItemType = (newItemType: string | null) => {
    setItemType(newItemType);
    setIndexUiState({
      ...indexUiState,
      page: 1,
      refinementList: { itemType: newItemType ? [newItemType] : [] },
    });
    refresh();
  };

  return (
    <>
      <Filters
        itemType={itemType}
        onSelect={updateItemType}
        totalHits={totalHits}
      />
      <Stats
        translations={{
          rootElementText: ({ nbHits }: { nbHits: number }) =>
            ngettext(
              msgid`${nbHits} result found`,
              `${nbHits} results found`,
              nbHits
            ),
        }}
      />
    </>
  );
}
